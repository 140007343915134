import { Country } from '@/enums/Country';
import { ConfigurationOption } from '@/enums/ConfigurationOption';
import { GlobalStep } from '@/enums/GlobalStep';
import { FixtureLevel } from '@/enums/FixtureLevel';
import { VehicleState } from '@/enums/VehicleState';

export interface Car {
    id: number;
    name: string;
    model_year: string;
    vehicle_state: VehicleState;
}

export interface Make {
    id: number;
    name: string;
}

export interface Color {
    id: number;
    name: string;
}

export interface AdditionalEquipment {
    id: number;
    name: string;
}

export interface ChargingEquipment {
    id: number;
    name: string;
}

export enum EventType {
    CUSTOMER_FORM_INPUT = 'customer-form-input',
    REACHED_SUMMARY = 'reached-summary',
}

export type OldValue =
    | boolean
    | number
    | string
    | Color
    | AdditionalEquipment[]
    | ChargingEquipment[];
export type NewValue =
    | boolean
    | number
    | string
    | Color
    | AdditionalEquipment[]
    | ChargingEquipment[];

interface Interaction {
    email: string;
    country: Country;
    make: Make;
    car: Car;
}

export interface Configuration {
    fixture_level: FixtureLevel;
    color: Color;
    term: number;
    km_mi: number;
    initial_payment: number;
}

export interface IConfiguratonInteraction extends Interaction {
    origin_step: GlobalStep;
    option: ConfigurationOption;
    old_value: OldValue;
    new_value: NewValue;
    current_config: Configuration;
}

export interface IStepNavigationInteraction extends Interaction {
    old_step: GlobalStep;
    new_step: GlobalStep;
}

export interface IEventInteraction extends Interaction {
    type: EventType;
}
