
    import { Component, Vue } from 'vue-property-decorator';
    import { Country } from '@/enums/Country';
    import { useGlobalStore } from '@/store/global';
    import { useConfiguratorStore } from '@/store/configurator';
    import { useCustomerStore } from '@/store/customer';
    import { ExtraOption } from '@/enums/ExtraOption';
    import InclusiveIcons from '@/components/InclusiveIcons.vue';
    import CarImage from '@/components/CarImage.vue';
    import { useOfferStore } from '@/store/offer';

    @Component({
        components: {
            InclusiveIcons,
            CarImage,
        },
    })
    export default class PriceTable extends Vue {
        country: any;
        extras: any;
        offerButtonIsLoading = false;

        get globalStore() {
            return useGlobalStore();
        }

        get configuratorStore() {
            return useConfiguratorStore();
        }

        get priceInDetailData() {
            return this.configuratorStore.priceResult?.price_in_detail;
        }

        get customerStore() {
            return useCustomerStore();
        }

        async sendOffer() {
            this.offerButtonIsLoading = true;
            const offerStore = useOfferStore();

            await offerStore.dispatchOffer();
            this.offerButtonIsLoading = false;
        }

        beforeCreate() {
            this.country = Country;
            this.extras = ExtraOption;
        }
    }
