import { IPriceRequest } from '@/interfaces/price/IPriceRequest';
import { HttpResponse, post } from '@/api/base';
import { IPriceResult } from '@/interfaces/price/IPriceResult';

export async function getPrice(req: IPriceRequest): Promise<HttpResponse<IPriceResult>> {
    return await post<IPriceResult>(
        `${process.env.VUE_APP_IC_SERVICE_HOST}/service/public/price/`,
        req,
    );
}
