
    import { Component, Vue } from 'vue-property-decorator';
    import { useGlobalStore } from '@/store/global';
    import { Country } from '@/enums/Country';
    import { useSelectorStore } from '@/store/selector';
    import { useCustomerStore } from '@/store/customer';
    import { VehicleState } from '@/enums/VehicleState';
    import CircleTag from '../tags/CircleTag.vue';

    @Component({
        components: {
            CircleTag,
        },
    })
    export default class InstaHero extends Vue {
        country: any;
        openCalendlyModal = false;

        get globalStore() {
            return useGlobalStore();
        }

        get selectorStore() {
            return useSelectorStore();
        }

        get customerStore() {
            return useCustomerStore();
        }

        get quizUrl() {
            const globalStore = useGlobalStore();

            return `${process.env.VUE_APP_QUIZ_URL}/${globalStore.country}`;
        }

        get hasOnlyNewCars() {
            if (this.selectorStore.allCars) {
                return !this.selectorStore.allCars?.find(
                    (car) => car.vehicle_state !== VehicleState.NEW_CAR,
                );
            }

            return false;
        }

        beforeCreate() {
            this.country = Country;
        }
    }
