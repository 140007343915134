// Use vee-validate to do some form validation

import { extend } from 'vee-validate';
import { email, required } from 'vee-validate/dist/rules';
import i18n from '@/i18n';
import { verifyEmail } from '@/api/customer';

// Extend rules
extend('required', {
    ...required,
    message: () => i18n.tc('forms.errors.mandatory_msg'),
});

extend('email', {
    ...email,
    message: () => i18n.tc('forms.errors.invalid_email_msg'),
});

/**
 * Custom validator to verify a mail address.
 * Asking our endpoint for valid address.
 */
extend('emailValid', {
    validate: async (value) => {
        // start the actual validation
        try {
            if (!value) return false;
            // ask verify-email service
            const emailResp = await verifyEmail(value);

            if (emailResp.parsedBody) {
                if (emailResp.parsedBody.valid) return true;
                else return i18n.tc('forms.errors.email_is_not_recognized_as_valid');
            }
        } catch (error) {
            // in case of error -> let it get through
            return true;
        }

        return true;
    },
    message: () => i18n.tc('forms.errors.invalid_email_msg'),
});

extend('nodigits', {
    validate: (value) => {
        // don't allow numbers
        const pattern = new RegExp('^([^0-9]*)$');

        return pattern.test(value);
    },
    message: () => i18n.tc('forms.errors.no_digits_msg'),
});

extend('phonenumber', {
    validate: (value) => {
        // only allow digits, "+", spaces and "/"
        const pattern = new RegExp('^([0-9]|\\+|\\-|\\/|\\s){4,}$');

        return pattern.test(value);
    },
    message: () => i18n.tc('forms.errors.invalid_phone_number_msg'),
});

extend('zipcode', {
    validate: (value) => {
        if (i18n.locale === 'en') {
            // trivial check for uk postcodes
            const ukPattern = new RegExp('^([A-Z]|[0-9]|\\s){6,8}$');

            return ukPattern.test(value);
        }

        if (i18n.locale === 'fr') {
            // trivial check for uk postcodes
            const frPattern = new RegExp('^([0-9]){5}$');

            return frPattern.test(value);
        }

        // trivial check for austrian and german postcodes
        const pattern = new RegExp('^([0-9]){4,5}$');

        return pattern.test(value);
    },
    message: () => i18n.tc('forms.errors.invalid_zip_code_msg'),
});
