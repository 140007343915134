import { defineStore } from 'pinia';
import { ICarFull } from '@/interfaces/car/ICarFull';
import { IConfiguration } from '@/interfaces/IConfiguration';
import { IPriceResult } from '@/interfaces/price/IPriceResult';
import { computed, ref, watch } from 'vue';
import { getCar } from '@/api/car';
import router from '@/router';
import { check404, getDeliveryString, isSendToInstalytics } from '@/util/misc';
import { useGlobalStore } from '@/store/global';
import { buildPriceReq } from '@/util/car';
import i18n from '@/i18n';
import { IPriceRequest } from '@/interfaces/price/IPriceRequest';
import { getChargingEquip } from '@/api/customer';
import { getPrice } from '@/api/price';
import { IConfiguratonInteraction, NewValue, OldValue } from '@/interfaces/instalytics/interaction';
import { useCustomerStore } from '@/store/customer';
import { ConfigurationOption } from '@/enums/ConfigurationOption';
import { IMake } from '@/interfaces/car/IMake';
import { GlobalStep } from '@/enums/GlobalStep';
import { addConfigurationInteraction } from '@/api/instalytics';
import { IExtraOptionItem } from '@/interfaces/IExtraOptionItem';
import { IAdditionalEquipmentOptionalItem } from '@/interfaces/IAdditionalEquipmentOptionalItem';
import { IChargingEquipment } from '@/interfaces/order/IChargingEquipment';

export const useConfiguratorStore = defineStore('configurator', () => {
    // give access to global and customer stores here
    const globalStore = useGlobalStore();
    const customerStore = useCustomerStore();

    const car = ref<ICarFull | undefined>(undefined);
    const carIsLoading = ref(false);
    const config = ref<IConfiguration>({
        color: undefined,
        fixture: undefined,
        term: 24,
        kilometerPerYear: 5000,
        initialPaymentAmount: undefined,
        canton: undefined,
    });
    const priceRequest = ref<IPriceRequest | undefined>(undefined);
    const priceResult = ref<IPriceResult | undefined>(undefined);
    const availableChargingEquipment = ref<IChargingEquipment[]>([]);

    // is used to manually ignore config changes for instalytics
    // e.g. config changes via code rather from user (Configurator.vue mounted hook e.g.)
    const ignoreChangesInstalytics = ref<boolean>(false);

    /**
     * Event handler when config has changed: retrieve prices and optional prices stuff.
     */
    async function onConfigChanged() {
        // Only retrieve price when config color is available in fixture.
        // Because of edge case when color is not available and switch to other fixture (see FixtureChooser.vue)
        const color = config.value.color;
        const fixtureLevel = config.value.fixture;

        if (!color || !fixtureLevel) {
            throw new Error('color and/or fixturelevel is undefined');
        }

        if (color.fixture_levels.includes(fixtureLevel)) {
            await retrievePrice();
        }
    }

    /**
     * Send data to instalytics.
     *
     * @param option
     * @param oldValue
     * @param newValue
     */
    async function sendToInstalytics(
        option: ConfigurationOption,
        oldValue: OldValue,
        newValue: NewValue,
    ) {
        if (
            !globalStore.country ||
            !car.value ||
            !config.value.fixture ||
            !config.value.color ||
            !config.value.initialPaymentAmount
        ) {
            throw new Error('Cannot create instalytics data');
        }

        const interaction: IConfiguratonInteraction = {
            origin_step: GlobalStep.CONFIGURATION,
            email: customerStore.customer.mail_address,
            country: globalStore.country,
            make: {
                id: (car.value.model_variant.model.make as IMake).id,
                name: (car.value.model_variant.model.make as IMake).name,
            },
            car: {
                id: car.value.id,
                name: `${car.value.model_variant.model.name} ${car.value.model_variant.name || ''}`,
                model_year: car.value.model_year,
                vehicle_state: car.value.vehicle_state,
            },
            option: option,
            old_value: oldValue,
            new_value: newValue,
            current_config: {
                fixture_level: config.value.fixture,
                color: {
                    id: config.value.color.id,
                    name: config.value.color.name,
                },
                term: config.value.term,
                km_mi: config.value.kilometerPerYear,
                initial_payment: parseInt(config.value.initialPaymentAmount.amount.toString()),
            },
        };

        if (isSendToInstalytics()) {
            await addConfigurationInteraction(interaction);
        }
    }

    watch(
        () => config.value.color,
        async (newValue, oldValue) => {
            // ignore initial values
            if (oldValue !== undefined && !ignoreChangesInstalytics.value) {
                await onConfigChanged();

                if (!newValue) {
                    throw new Error('Cannot send to instalytics, newValue undefined.');
                }

                await sendToInstalytics(
                    ConfigurationOption.COLOR,
                    { id: oldValue.id, name: oldValue.name },
                    { id: newValue.id, name: newValue.name },
                );
            }
        },
    );

    watch(
        () => config.value.fixture,
        async (newValue, oldValue) => {
            if (oldValue !== undefined && !ignoreChangesInstalytics.value) {
                await onConfigChanged();

                if (!newValue) {
                    throw new Error('Cannot send to instalytics, newValue undefined.');
                }

                await sendToInstalytics(ConfigurationOption.FIXTURE_LEVEL, oldValue, newValue);
            }
        },
    );

    watch(
        () => config.value.term,
        async (newValue, oldValue) => {
            if (oldValue !== undefined && !ignoreChangesInstalytics.value) {
                await onConfigChanged();

                if (!newValue) {
                    throw new Error('Cannot send to instalytics, newValue undefined.');
                }

                await sendToInstalytics(ConfigurationOption.TERM, oldValue, newValue);
            }
        },
    );

    watch(
        () => config.value.kilometerPerYear,
        async (newValue, oldValue) => {
            if (oldValue !== undefined && !ignoreChangesInstalytics.value) {
                await onConfigChanged();

                if (!newValue) {
                    throw new Error('Cannot send to instalytics, newValue undefined.');
                }

                await sendToInstalytics(ConfigurationOption.KILOMETRAGE, oldValue, newValue);
            }
        },
    );

    watch(
        () => config.value.initialPaymentAmount,
        async (newValue, oldValue) => {
            if (oldValue !== undefined && !ignoreChangesInstalytics.value) {
                await onConfigChanged();

                if (!newValue) {
                    throw new Error('Cannot send to instalytics, newValue undefined.');
                }

                await sendToInstalytics(
                    ConfigurationOption.INITIAL_PAYMENT,
                    oldValue.amount,
                    newValue.amount,
                );
            }
        },
    );

    watch(
        () => config.value.canton,
        async () => {
            if (!ignoreChangesInstalytics.value) {
                await onConfigChanged();
            }
        },
    );

    async function retrieveCar() {
        carIsLoading.value = true;

        if (!globalStore.country) {
            throw new Error('country is undefined');
        }

        const carResp = await getCar(router.currentRoute.params.slug, globalStore.country);
        check404(carResp);

        if (carResp.parsedBody) {
            car.value = carResp.parsedBody;
            carIsLoading.value = false;
        } else {
            throw new Error('cannot get car');
        }
    }

    async function retrievePrice() {
        ignoreChangesInstalytics.value = true;

        if (!globalStore.country) {
            throw new Error('country is undefined');
        }

        if (!car.value) {
            throw new Error('car is undefinfed');
        }

        priceRequest.value = buildPriceReq(
            car.value,
            config.value,
            globalStore.country,
            priceRequest.value,
        );

        const priceResponse = await getPrice(priceRequest.value);

        if (priceResponse.parsedBody) {
            priceResult.value = priceResponse.parsedBody;
        } else {
            throw new Error('cannot get price');
        }

        ignoreChangesInstalytics.value = false;
    }

    // deliveryString getter
    const deliveryString = computed(() => {
        if (priceResult.value && globalStore.iaInterval !== undefined) {
            return getDeliveryString(priceResult.value.delivery_time.days, globalStore.iaInterval);
        }
        return i18n.tc('messages.on_request');
    });

    const chosenChargingEquipment = computed(() => {
        const result: IChargingEquipment[] = [];

        if (priceRequest.value && priceResult.value) {
            for (const reqItem of availableChargingEquipment.value) {
                const option = availableChargingEquipment.value.find(
                    (item) => item.id === reqItem.id,
                );

                if (option) {
                    result.push(option);
                }
            }
        }

        return result;
    });

    const chosenAdditionalEquipment = computed<IAdditionalEquipmentOptionalItem[]>(() => {
        const result: IAdditionalEquipmentOptionalItem[] = [];

        if (priceRequest.value && priceResult.value) {
            for (const reqItem of priceRequest.value.additional_equipment) {
                const option = priceResult.value.optionals.additional_equipment.find(
                    (item) => item.equip_type === reqItem.type && item.id == reqItem.id,
                );

                if (option) {
                    result.push(option);
                }
            }
        }

        return result;
    });

    const chosenExtraOptions = computed<IExtraOptionItem[]>(() => {
        const result: IExtraOptionItem[] = [];

        if (priceRequest.value && priceResult.value) {
            for (const reqItem of priceRequest.value.extra_options) {
                const option = priceResult.value.optionals.extra_options.find(
                    (item) => item.name === reqItem,
                );

                if (option) {
                    result.push(option);
                }
            }
        }

        return result;
    });

    /**
     * Retrieve the available charging equipments by car id.
     */
    async function retrieveChargingEquip() {
        if (!priceRequest.value) {
            throw new Error('Invalid price request.');
        }

        if (!globalStore.country) {
            throw new Error('Country is undefined.');
        }

        const chargingEquipResponse = await getChargingEquip(
            priceRequest.value.car_id,
            globalStore.country,
        );

        if (chargingEquipResponse.parsedBody) {
            // Added sorting for special INSTADRIVE equip so it is first
            chargingEquipResponse.parsedBody.sort((a, b) => {
                return b.description.toLowerCase().includes('instadrive') ? 1 : -1;
            });

            availableChargingEquipment.value = chargingEquipResponse.parsedBody;
        } else {
            throw new Error('Cannot retrieve charging equipments.');
        }
    }

    return {
        car,
        carIsLoading,
        config,
        priceRequest,
        priceResult,
        availableChargingEquipment,
        chosenExtraOptions,
        chosenAdditionalEquipment,
        chosenChargingEquipment,
        retrieveCar,
        retrievePrice,
        retrieveChargingEquip,
        deliveryString,
        ignoreChangesInstalytics,
        sendToInstalytics,
    };
});
