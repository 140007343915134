import { get, HttpResponse } from '@/api/base';
import { Country } from '@/enums/Country';
import { IGlobal } from '@/interfaces/IGlobal';
import { ICar } from '@/interfaces/car/ICar';
import { ICarFull } from '@/interfaces/car/ICarFull';
import { IBrand } from '@/interfaces/IBrand';
import { IVehicleClass } from '@/interfaces/car/IVehicleClass';
import { IVehicleType } from '@/interfaces/car/IVehicleType';

export async function getGlobals(c: Country): Promise<HttpResponse<IGlobal>> {
    return await get<IGlobal>(
        `${process.env.VUE_APP_IC_SERVICE_HOST}/service/public/globals/${c}/`,
    );
}

export async function getCarList(c: Country, is_b2b = false): Promise<HttpResponse<ICar[]>> {
    return await get<ICar[]>(
        `${process.env.VUE_APP_IC_SERVICE_HOST}/service/public/cars/${c}/?b2b=${is_b2b}`,
    );
}

export async function getCar(slug: string, c: Country): Promise<HttpResponse<ICarFull>> {
    return await get<ICarFull>(
        `${process.env.VUE_APP_IC_SERVICE_HOST}/service/public/car/${c}/${slug}/`,
    );
}

export async function getBrands(country: Country) {
    return await get<IBrand[]>(
        `${process.env.VUE_APP_IC_SERVICE_HOST}/service/public/brands/${country}/`,
    );
}

export async function getVehicleClass(country: Country) {
    return await get<IVehicleClass[]>(
        `${process.env.VUE_APP_IC_SERVICE_HOST}/service/public/car-categories/${country}/`,
    );
}

export async function getVehicleType(country: Country) {
    return await get<IVehicleType[]>(
        `${process.env.VUE_APP_IC_SERVICE_HOST}/service/public/car-types/${country}/`,
    );
}
