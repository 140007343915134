
    import { ValidationProvider } from 'vee-validate';
    import { Component, Vue } from 'vue-property-decorator';
    import PriceTableCondensed from '@/components/PriceTableCondensed.vue';
    import { useConfiguratorStore } from '@/store/configurator';
    import { useCustomerStore } from '@/store/customer';
    import { useGlobalStore } from '@/store/global';
    import { GlobalStep } from '@/enums/GlobalStep';
    import { Country } from '@/enums/Country';
    import { addEventInteraction } from '@/api/instalytics';
    import { IMake } from '@/interfaces/car/IMake';
    import { EventType } from '@/interfaces/instalytics/interaction';
    import WithdrawalBadge from '../WithdrawalBadge.vue';
    import BottomPriceBar from '@/components/BottomPriceBar.vue';
    import CalendlyWidget from '../CalendlyWidget.vue';
    import ExtraOptions from '../additionals/ExtraOptions.vue';
    import ChargingEquipment from '../additionals/ChargingEquipment.vue';
    import PriceTable from '../PriceTable.vue';
    import CustomerSurveyFull from '@/components/CustomerSurveyFull.vue';
    import { isSendToInstalytics } from '@/util/misc';

    @Component({
        components: {
            PriceTableCondensed,
            PriceTable,
            WithdrawalBadge,
            ValidationProvider,
            BottomPriceBar,
            CalendlyWidget,
            ExtraOptions,
            ChargingEquipment,
            CustomerSurveyFull,
        },
    })
    export default class Summary extends Vue {
        country: any;

        get globalStore() {
            return useGlobalStore();
        }

        get configuratorStore() {
            return useConfiguratorStore();
        }

        get customerStore() {
            return useCustomerStore();
        }

        goToConfiguration() {
            if (!this.configuratorStore.car) {
                throw new Error('car is undefined');
            }

            this.$router.push({
                name: 'configure',
                params: { slug: this.configuratorStore.car.url_slug },
            });
        }

        async mounted() {
            if (!this.configuratorStore.car || !this.globalStore.country) {
                throw new Error('car or country undefined!');
            }

            this.globalStore.currentStep = GlobalStep.PRICE_DETAIL;

            if (isSendToInstalytics()) {
                await addEventInteraction({
                    email: this.customerStore.customer.mail_address,
                    country: this.globalStore.country,
                    make: {
                        id: (this.configuratorStore.car.model_variant.model.make as IMake).id,
                        name: (this.configuratorStore.car.model_variant.model.make as IMake).name,
                    },
                    car: {
                        id: this.configuratorStore.car.id,
                        name: `${this.configuratorStore.car.model_variant.model.name} ${
                            this.configuratorStore.car.model_variant.name || ''
                        }`,
                        model_year: this.configuratorStore.car.model_year,
                        vehicle_state: this.configuratorStore.car.vehicle_state,
                    },
                    type: EventType.REACHED_SUMMARY,
                });
            }
        }

        beforeCreate() {
            this.country = Country;
        }
    }
