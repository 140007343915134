
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import CarTag from '@/components/tags/CarTag.vue';
    import LazyImage from '@/components/LazyImage.vue';
    import CrossPrice from '@/components/CrossPrice.vue';
    import { getImageURL as getCarImageURL, getName as getCarName } from '@/util/car';
    import { ICar } from '@/interfaces/car/ICar';
    import { decapitalize, getDeliveryString } from '@/util/misc';
    import i18n from '@/i18n';
    import { useGlobalStore } from '@/store/global';
    import { Country } from '@/enums/Country';
    import { useCustomerStore } from '@/store/customer';
    import CircleTag from '@/components/tags/CircleTag.vue';

    @Component({
        components: {
            CrossPrice,
            LazyImage,
            CarTag,
            CircleTag,
        },
    })
    export default class CarGridItem extends Vue {
        @Prop() car!: ICar;
        showExtendedInfo = false;
        country = Country;

        get globalStore() {
            return useGlobalStore();
        }

        get customerStore() {
            return useCustomerStore();
        }

        get minAvailabilityString() {
            const deliveryString = getDeliveryString(
                this.car.min_delivery_time_combination.days,
                this.globalStore.iaInterval,
            );

            if (
                deliveryString === i18n.tc('words.immediately') ||
                deliveryString === i18n.tc('messages.on_request')
            ) {
                // in case of 'immediately' or 'on request', return the string
                return decapitalize(deliveryString);
            }

            // otherwise, prepend 'from' to delivery string for displaying in grid item
            return decapitalize(`${i18n.tc('words.from')} ${deliveryString}`);
        }

        get carImgUrl() {
            let imgUrl = `${getCarImageURL(this.car.image)}?w=500`;

            if (this.car.watermark_image) {
                imgUrl += `&wat=1&wat_url=${getCarImageURL(
                    this.car.watermark_image.image,
                )}&wat_scale=100`;
            }

            if (process.env.NODE_ENV === 'development') {
                imgUrl = imgUrl.replaceAll('localhost:8001', 'id-cs.com');
            }

            return `https://adhgfzvyfq.cloudimg.io/v7/${imgUrl}`;
        }

        get carName() {
            return getCarName(this.car);
        }

        get showedPrice() {
            if (this.car.stickerpricedata && this.car.stickerpricedata.price) {
                if (this.customerStore.customer.is_company) {
                    // sticker price: calculate the net value on frontend side
                    return [
                        this.globalStore.getNetValue(this.car.stickerpricedata.price),
                        this.globalStore.getNetValue(this.car.stickerpricedata.default_price),
                    ];
                } else {
                    return [
                        this.car.stickerpricedata.price,
                        this.car.stickerpricedata.default_price,
                    ];
                }
            } else {
                return null;
            }
        }

        buildProductPageLink() {
            let keyword = 'elektroauto';

            if (this.globalStore.country === this.country.FR) {
                keyword = 'voiture-electrique';
            }

            return `https://${process.env.VUE_APP_WEBSITE_DOMAIN}/${this.globalStore.country}/${keyword}/${this.car.model_variant.model.url_path}/`;
        }
    }
