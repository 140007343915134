
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import { getName } from '@/util/car';
    import { ICarFull } from '@/interfaces/car/ICarFull';
    import { IColor } from '@/interfaces/car/IColor';
    import InclusiveIcons from '@/components/InclusiveIcons.vue';
    import { normalizeSvgViewBoxes } from '@/util/misc';
    import { Country } from '@/enums/Country';
    import CarImageLoader from '@/components/CarImageLoader.vue';
    import WithdrawalBadge from './WithdrawalBadge.vue';

    @Component({
        components: {
            InclusiveIcons,
            CarImageLoader,
            WithdrawalBadge,
        },
    })
    export default class CarImage extends Vue {
        @Prop({ default: false }) isFull!: boolean;
        @Prop() car?: ICarFull;
        @Prop() color?: IColor;
        @Prop({ default: true }) showInclusiveIcons!: boolean;
        @Prop({ default: 'row' }) mode!: string;
        @Prop({ default: false }) isLoading!: boolean;
        @Prop({ default: false }) hideWatermark?: boolean;
        @Prop({ default: false }) showTrialRequestButton?: boolean;
        country: any;

        getCarName() {
            return getName(this.car);
        }

        getImageStyle() {
            if (!this.isFull) {
                return 'width: 250px;';
            }
        }

        get carImgSrc() {
            let imgUrl = this.color?.car_image;

            if (!this.isFull) {
                imgUrl += '?w=500';
            } else {
                imgUrl += '?w=1300';
            }

            if (this.car?.watermark_image && !this.hideWatermark) {
                imgUrl += `&wat=1&wat_url=${this.car.watermark_image.image}&wat_scale=100`;
            }

            if (process.env.NODE_ENV === 'development') {
                imgUrl = imgUrl?.replaceAll('localhost:8001', 'id-cs.com');
            }

            return `https://adhgfzvyfq.cloudimg.io/v7/${imgUrl}`;
        }

        mounted() {
            this.$nextTick(function () {
                // Code that will run only after the
                // entire view has been rendered
                normalizeSvgViewBoxes(this.$el.querySelectorAll('.make-icon > svg'));
            });
        }

        updated() {
            normalizeSvgViewBoxes(this.$el.querySelectorAll('.make-icon > svg'));
        }

        beforeCreate() {
            this.country = Country;
        }
    }
