
    import { Component, Vue } from 'vue-property-decorator';
    import { ExtraOption } from '@/enums/ExtraOption';
    import { Country } from '@/enums/Country';
    import { useGlobalStore } from '@/store/global';
    import { useConfiguratorStore } from '@/store/configurator';
    import { ConfigurationOption, convertOption } from '@/enums/ConfigurationOption';
    import { useCustomerStore } from '@/store/customer';
    import { VehicleState } from '@/enums/VehicleState';

    @Component
    export default class ExtraOptions extends Vue {
        extras: any;
        country: any;
        vehicleState = VehicleState;

        get globalStore() {
            return useGlobalStore();
        }

        get configuratorStore() {
            return useConfiguratorStore();
        }

        get customerStore() {
            return useCustomerStore();
        }

        /**
         *  Click handler for switching delivery.
         */
        async onDeliveryClick() {
            if (!this.configuratorStore.priceRequest) {
                throw new Error('invalid price request');
            }

            // save the current/old value for instalytics
            const oldValue = this.configuratorStore.priceRequest.delivery;

            this.configuratorStore.priceRequest.delivery =
                !this.configuratorStore.priceRequest.delivery;
            await this.configuratorStore.retrievePrice();

            await this.configuratorStore.sendToInstalytics(
                ConfigurationOption.DELIVERY,
                oldValue,
                this.configuratorStore.priceRequest.delivery,
            );
        }

        /**
         * Click handler for adding/removing an extra item.
         *
         * @param extra
         */
        async onExtraClick(extra: ExtraOption) {
            if (!this.configuratorStore.priceRequest) {
                throw new Error('invalid price request');
            }

            if (this.configuratorStore.priceRequest.extra_options.includes(extra)) {
                // remove from price request
                for (const [i, eo] of this.configuratorStore.priceRequest.extra_options.entries()) {
                    if (eo === extra) {
                        this.configuratorStore.priceRequest.extra_options?.splice(i, 1);
                        await this.configuratorStore.retrievePrice();

                        // send the extra option change to instalytics
                        await this.configuratorStore.sendToInstalytics(
                            convertOption(eo as ExtraOption),
                            true,
                            false,
                        );
                    }
                }
            } else {
                const item = this.configuratorStore.priceResult?.optionals?.extra_options.find(
                    (item) => item.name === extra,
                );

                if (item) {
                    // add extra item to extra options
                    this.configuratorStore.priceRequest.extra_options?.push(item.name);
                    await this.configuratorStore.retrievePrice();

                    // send the extra option change to instalytics
                    await this.configuratorStore.sendToInstalytics(
                        convertOption(item.name as ExtraOption),
                        false,
                        true,
                    );
                }
            }
        }

        beforeCreate() {
            this.extras = ExtraOption;
            this.country = Country;
        }
    }
