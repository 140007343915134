var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.configuratorStore.priceRequest && _vm.configuratorStore.priceResult)?_c('div',{staticClass:"additional-fixture",attrs:{"id":"extraoptions"}},[_c('div',{staticClass:"extra-options-items"},[_c('button',{staticClass:"finalizing-item",class:{
                active: !_vm.configuratorStore.priceRequest.delivery,
            },on:{"click":_vm.onDeliveryClick}},[_c('img',{staticClass:"svg",attrs:{"src":require("@/assets/img/extras/pick-up.svg"),"alt":_vm.$tc('messages.pick_up_from_dealer')}}),_c('strong',[_vm._v(_vm._s(_vm.$tc('messages.pick_up_from_dealer')))]),(_vm.configuratorStore.car?.vehicle_state !== _vm.vehicleState.USED_CAR)?_c('span',[_vm._v(" "+_vm._s(_vm.$tc('messages.inkl_free_introduction'))+" ")]):_vm._e(),_c('span',{staticClass:"price-tag"},[_c('strong',[(_vm.customerStore.customer.is_company)?[_vm._v(" "+_vm._s(_vm.globalStore.displayPrice(0))+" "),_c('span',{staticClass:"net"},[_vm._v(_vm._s(_vm.$tc('messages.final.net').toLowerCase()))])]:[_vm._v(" "+_vm._s(_vm.globalStore.displayPrice(0))+" ")]],2)])]),_c('button',{staticClass:"finalizing-item",class:{
                active: _vm.configuratorStore.priceRequest.delivery,
            },on:{"click":_vm.onDeliveryClick}},[_c('img',{staticClass:"svg",attrs:{"src":require("@/assets/img/extras/delivery.svg"),"alt":_vm.$tc('words.car_delivery')}}),_c('strong',[_vm._v(_vm._s(_vm.$tc('words.car_delivery')))]),_c('span',[_vm._v(" "+_vm._s(_vm.$tc('messages.draft.delivery_desc'))+" "),(_vm.globalStore.country === _vm.country.AT)?[_vm._v(" (nur ab 100km Entfernung von Wien; "+_vm._s(_vm.$tc('messages.plus_delivery_time'))+") ")]:[_vm._v(" ("+_vm._s(_vm.$tc('messages.plus_delivery_time'))+") ")]],2),_c('span',{staticClass:"price-tag"},[_c('strong',[(_vm.customerStore.customer.is_company)?[_vm._v(" + "+_vm._s(_vm.globalStore.displayPrice( _vm.configuratorStore.priceResult.optionals.delivery.price.after, ))+" "),_c('span',{staticClass:"net"},[_vm._v(_vm._s(_vm.$tc('messages.final.net').toLowerCase()))])]:[_vm._v(" + "+_vm._s(_vm.globalStore.displayPrice( _vm.configuratorStore.priceResult.optionals.delivery.price.pre, ))+" ")]],2),_vm._v(" "+_vm._s(_vm.$tc('messages.final.price_once'))+" ")])]),(_vm.globalStore.country !== _vm.country.FR)?_c('button',{staticClass:"finalizing-item",class:{
                active: _vm.configuratorStore.priceRequest.extra_options.includes(
                    _vm.extras.TIRE_PACKAGE_PLUS,
                ),
            },on:{"click":function($event){return _vm.onExtraClick(_vm.extras.TIRE_PACKAGE_PLUS)}}},[_c('img',{staticClass:"svg",attrs:{"src":require("@/assets/img/extras/tire_package.svg"),"alt":_vm.$tc('messages.draft.tyre_package_plus')}}),_c('strong',[_vm._v(_vm._s(_vm.$tc('messages.draft.tyre_package_plus')))]),_c('span',[_vm._v(_vm._s(_vm.$tc('messages.draft.tyre_package_desc')))]),_c('span',{staticClass:"price-tag"},[_c('strong',[_vm._v(" + "+_vm._s(_vm.globalStore.displayPrice( _vm.configuratorStore.priceResult.optionals.extra_options.find( (item) => item.name === _vm.extras.TIRE_PACKAGE_PLUS, )?.increase_in_price.pre, ))+" ")]),(_vm.customerStore.customer.is_company)?[_c('span',{staticClass:"net"},[_vm._v(_vm._s(_vm.$tc('messages.final.net').toLowerCase())+" "+_vm._s(_vm.$tc('words.monthly_abbr')))])]:[_vm._v(" "+_vm._s(_vm.$tc('words.monthly'))+" ")]],2)]):_vm._e(),_c('button',{staticClass:"finalizing-item",class:{
                active: _vm.configuratorStore.priceRequest.extra_options.includes(
                    _vm.extras.REMOVE_BRANDING,
                ),
            },on:{"click":function($event){return _vm.onExtraClick(_vm.extras.REMOVE_BRANDING)}}},[_c('img',{staticClass:"svg",attrs:{"src":require("@/assets/img/extras/remove_branding.svg"),"alt":_vm.$tc('messages.draft.remove_branding')}}),_c('strong',[_vm._v(_vm._s(_vm.$tc('messages.draft.remove_branding')))]),_c('span',[_vm._v(_vm._s(_vm.$tc('messages.draft.remove_branding_desc')))]),_c('span',{staticClass:"price-tag"},[_c('strong',[_vm._v(" + "+_vm._s(_vm.globalStore.displayPrice( _vm.configuratorStore.priceResult.optionals.extra_options.find( (item) => item.name === _vm.extras.REMOVE_BRANDING, )?.increase_in_price.pre, ))+" ")]),(_vm.customerStore.customer.is_company)?[_c('span',{staticClass:"net"},[_vm._v(_vm._s(_vm.$tc('messages.final.net').toLowerCase())+" "+_vm._s(_vm.$tc('words.monthly_abbr')))])]:[_vm._v(" "+_vm._s(_vm.$tc('words.monthly'))+" ")]],2)]),_c('button',{staticClass:"finalizing-item",class:{
                active: _vm.configuratorStore.priceRequest.extra_options.includes(
                    _vm.extras.WARRANTY_ASSISTANCE,
                ),
            },on:{"click":function($event){return _vm.onExtraClick(_vm.extras.WARRANTY_ASSISTANCE)}}},[_c('img',{staticClass:"svg",attrs:{"src":require("@/assets/img/extras/mobility_warranty.svg"),"alt":_vm.$tc('words.mobility_warranty')}}),_c('strong',[_vm._v(_vm._s(_vm.$tc('words.mobility_warranty')))]),_c('span',[_vm._v(_vm._s(_vm.$tc('messages.draft.mobility_warranty_desc')))]),_c('span',{staticClass:"price-tag"},[_c('strong',[_vm._v(" + "+_vm._s(_vm.globalStore.displayPrice( _vm.configuratorStore.priceResult.optionals.extra_options.find( (item) => item.name === _vm.extras.WARRANTY_ASSISTANCE, )?.increase_in_price.pre, ))+" ")]),(_vm.customerStore.customer.is_company)?[_vm._v(" "+_vm._s(_vm.$tc('messages.final.net').toLowerCase())+" "+_vm._s(_vm.$tc('words.monthly_abbr'))+" ")]:[_vm._v(" "+_vm._s(_vm.$tc('words.monthly'))+" ")]],2)])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }