import { render, staticRenderFns } from "./CustomerSurveyFull.vue?vue&type=template&id=199438dc&scoped=true&"
import script from "./CustomerSurveyFull.vue?vue&type=script&lang=ts&setup=true&"
export * from "./CustomerSurveyFull.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./CustomerSurveyFull.vue?vue&type=style&index=0&id=199438dc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "199438dc",
  null
  
)

export default component.exports