
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import { ObserveVisibility } from 'vue-observe-visibility';
    import { BModalComponent } from 'buefy/types/components';
    import CircleTag from '@/components/tags/CircleTag.vue';
    import { FixtureLevel } from '@/enums/FixtureLevel';
    import { buildPriceReq, getMakeIcon, getSCE, isUnavailableFixture } from '@/util/car';
    import { normalizeSvgViewBoxes } from '@/util/misc';
    import { ICarFull } from '@/interfaces/car/ICarFull';
    import { getPrice } from '@/api/price';
    import { useGlobalStore } from '@/store/global';
    import { useConfiguratorStore } from '@/store/configurator';
    import { useCustomerStore } from '@/store/customer';

    // @see https://github.com/Akryum/vue-observe-visibility
    Vue.directive('observe-visibility', ObserveVisibility);

    @Component({
        components: {
            CircleTag,
        },
    })
    export default class FixtureChooserModalContent extends Vue {
        @Prop() car!: ICarFull;
        priceLevel2: number | null = null;
        priceLevel3: number | null = null;
        fixture!: any;
        mobileScrollStatus: FixtureLevel = FixtureLevel.LEVEL1;

        get globalStore() {
            return useGlobalStore();
        }

        get configuratorStore() {
            return useConfiguratorStore();
        }

        get customerStore() {
            return useCustomerStore();
        }

        /**
         * Choose the given fixture level.
         *
         * @param f fixture level
         */
        choose(f: FixtureLevel) {
            // first of all: close the modal window, call close() on $parent, @see https://buefy.org/documentation/modal
            (this.$parent as BModalComponent).close();

            // write the fixture to store
            this.configuratorStore.config.fixture = f;
        }

        get makeIcon() {
            return getMakeIcon(this.car);
        }

        getSCE(f: FixtureLevel) {
            if (!this.car) {
                return [];
            }

            return getSCE(f, this.car);
        }

        /**
         * Check for generally unavailable fixture package.
         *
         * @param f fixture level
         */
        isGenerallyUnavailable(f: FixtureLevel) {
            if (!this.car) {
                throw new Error('car is undefined');
            }

            return isUnavailableFixture(f, this.car);
        }

        /**
         * Check for unavailable fixture <-> color combination
         *
         * @param f fixture level
         */
        isAvailableWithColor(f: FixtureLevel) {
            if (!this.configuratorStore.config.color) {
                throw new Error('configColor is undefined');
            }

            return (
                this.configuratorStore.config.color.fixture_levels &&
                this.configuratorStore.config.color.fixture_levels.includes(f)
            );
        }

        /**
         * Wrapper to check availability in template.
         *
         * @param f fixture level
         */
        isUnavailable(f: FixtureLevel) {
            return this.isGenerallyUnavailable(f) || !this.isAvailableWithColor(f);
        }

        /**
         * Track the fixture column's visibility independend of clicking on arrow or scrolling.
         */
        mobileScrollStatusVisibilityChanged(isVisible: boolean, entry: IntersectionObserverEntry) {
            if (isVisible) {
                if (entry.target.classList.contains('silver')) {
                    this.mobileScrollStatus = FixtureLevel.LEVEL1;
                } else if (entry.target.classList.contains('gold')) {
                    this.mobileScrollStatus = FixtureLevel.LEVEL2;
                } else if (entry.target.classList.contains('platinum')) {
                    this.mobileScrollStatus = FixtureLevel.LEVEL3;
                }
            }
        }

        /**
         * Scroll the modal content to next fixture.
         *
         * @param ev event
         */
        scroll(ev: Event) {
            const elem = ev.currentTarget as HTMLElement;
            const modalContent = document.querySelector(
                '.fixture-modal .modal-content',
            ) as HTMLElement;

            if (elem.classList.contains('right')) {
                modalContent.scrollLeft += screen.width;
            } else if (elem.classList.contains('left')) {
                modalContent.scrollLeft -= screen.width;
            }
        }

        /**
         * Check for generally unavailable levels or unavailable fixture due to chosen color.
         */
        hasUnavailableLevels() {
            return (
                this.isGenerallyUnavailable(FixtureLevel.LEVEL1) ||
                this.isGenerallyUnavailable(FixtureLevel.LEVEL2) ||
                this.isGenerallyUnavailable(FixtureLevel.LEVEL3) ||
                this.isAvailableWithColor(FixtureLevel.LEVEL1) ||
                this.isAvailableWithColor(FixtureLevel.LEVEL2) ||
                this.isAvailableWithColor(FixtureLevel.LEVEL3)
            );
        }

        async mounted() {
            this.$nextTick(function () {
                // Code that will run only after the
                // entire view has been rendered
                normalizeSvgViewBoxes(this.$el.querySelectorAll('.make-icon > svg'));
            });

            if (!this.car || !this.configuratorStore.config || !this.globalStore.country) {
                throw new Error('car or config or country undefined!');
            }

            if (!this.hasUnavailableLevels()) {
                // don't do that if there are unavailable fixture levels.
                const priceReq = buildPriceReq(
                    this.car,
                    this.configuratorStore.config,
                    this.globalStore.country,
                );

                // get and set the prices according to fixture package on mount
                let level1Price;
                let level2Price;
                let level3Price;

                priceReq.fixture_level = FixtureLevel.LEVEL1;
                const level1Resp = await getPrice(priceReq);

                if (level1Resp.parsedBody) {
                    level1Price = level1Resp.parsedBody.cleaned_final_price.pre;
                } else {
                    throw new Error('cannot get price');
                }

                priceReq.fixture_level = FixtureLevel.LEVEL2;
                const level2Resp = await getPrice(priceReq);

                if (level2Resp.parsedBody) {
                    level2Price = level2Resp.parsedBody.cleaned_final_price.pre;
                } else {
                    throw new Error('cannot get price');
                }
                this.priceLevel2 = level2Price - level1Price;

                priceReq.fixture_level = FixtureLevel.LEVEL3;
                const level3Resp = await getPrice(priceReq);

                if (level3Resp.parsedBody) {
                    level3Price = level3Resp.parsedBody.cleaned_final_price.pre;
                } else {
                    throw new Error('cannot get price');
                }
                this.priceLevel3 = level3Price - level1Price;
            }
        }

        beforeCreate() {
            this.fixture = FixtureLevel;
        }
    }
