var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"additional-fixture"},[_c('div',{staticClass:"equipment-items"},[_vm._l((_vm.configuratorStore.availableChargingEquipment),function(equipItem){return _c('button',{key:equipItem.id,class:{
                    active: _vm.hasEquip(equipItem.id),
                    'finalizing-item': true,
                    'instadrive-equip': equipItem.description
                        .toLowerCase()
                        .includes('instadrive'),
                },on:{"click":function($event){return _vm.onEquipClick(equipItem)}}},[_c('img',{attrs:{"src":equipItem.icon,"alt":equipItem.name}}),_c('strong',[_vm._v(_vm._s(equipItem.description))]),_c('span',{staticClass:"price-tag"},[_c('strong',[_vm._v(" + "),(_vm.customerStore.customer.is_company)?[_vm._v(" "+_vm._s(_vm.globalStore.displayPrice(equipItem.price.after))+" ")]:[_vm._v(" "+_vm._s(_vm.globalStore.displayPrice(equipItem.price.pre))+" ")],(_vm.customerStore.customer.is_company)?_c('span',{staticClass:"net"},[_vm._v(" "+_vm._s(_vm.$tc('messages.final.net').toLowerCase())+" ")]):_vm._e()],2),_vm._v(" "+_vm._s(_vm.$tc('messages.final.price_once'))+" ")])])}),(!_vm.configuratorStore.availableChargingEquipment.length)?[_c('i',[_vm._v(" "+_vm._s(_vm.$tc('messages.draft.charging_equipment_none'))+" ")])]:_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }