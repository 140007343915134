
    import { Vue, Component, Watch } from 'vue-property-decorator';
    import Header from '@/components/Header.vue';
    import { getCC, isBusinessMode } from '@/util/queryfilter';
    import { Country } from '@/enums/Country';
    import Footer from '@/components/Footer.vue';
    import { setI18nLanguage } from '@/i18n';
    import { useConfiguratorStore } from '@/store/configurator';
    import { useGlobalStore } from '@/store/global';
    import { useCustomerStore } from '@/store/customer';
    import { useSemiLoginStore } from '@/store/semilogin';
    import SemiLoginModal from '@/components/SemiLoginModal.vue';
    import BusinessDetailsModal from './components/BusinessDetailsModal.vue';
    import { computed } from 'vue';

    @Component({
        components: {
            Footer,
            Header,
            SemiLoginModal,
            BusinessDetailsModal,
        },
    })
    export default class App extends Vue {
        get globalStore() {
            return useGlobalStore();
        }

        get configuratorStore() {
            return useConfiguratorStore();
        }

        get customerStore() {
            return useCustomerStore();
        }

        get semiLoginStore() {
            return useSemiLoginStore();
        }

        semiLoginModalOpen = computed(() => {
            return (
                (!this.semiLoginStore.state.loggedIn && this.$route.name !== 'selector') ||
                this.semiLoginStore.state.logoutRequested
            );
        });

        // Send email change to posthog
        @Watch('customerStore.customer.mail_address')
        async onEmailChange() {
            if (this.customerStore.customer.mail_address) {
                // @ts-ignore
                this.$posthog.identify(this.customerStore.customer.mail_address, {
                    email: this.customerStore.customer.mail_address,
                    customer_data_configurator: this.customerStore.customer,
                });
            }
        }

        // Open business details modal when user is logged in and switching to business and didn't already fill out the infos
        businessDetailsModalOpen = false;

        @Watch('customerStore.customer.is_company')
        async onCompanyChange() {
            if (
                this.customerStore.customer.is_company &&
                this.semiLoginStore.state.loggedIn &&
                !this.semiLoginModalOpen &&
                (!this.customerStore.customer.company || !this.customerStore.customer.fleet_size)
            ) {
                this.businessDetailsModalOpen = true;
            } else if (!this.semiLoginModalOpen) {
                if (this.semiLoginStore.state.loggedIn) {
                    await this.customerStore.dispatchCustomerAddOrUpdate();
                }
                this.businessDetailsModalOpen = false;
            }
        }

        /*
         *  Actual 'app initialization', has to be here rather than in mounted(),
         *  because children components gets mounted before their parents.
         */
        created() {
            const cc = getCC(this.$route);

            if (cc !== undefined) {
                this.globalStore.country = cc;
            } else {
                // no url parameter set
                if (!this.globalStore.country) {
                    // store's country is undefined too, use de as default
                    this.globalStore.country = Country.DE;
                }
            }

            if (!this.globalStore.country) {
                throw new Error('country is undefined');
            }

            // set the i18n language according to country
            setI18nLanguage(this.globalStore.country.toString());

            const businessMode = isBusinessMode(this.$route);

            this.customerStore.customer.is_company = businessMode;

            // dispatch ia interval, sales channels and used brands retrieving
            this.globalStore.retrieveIntervals();
            this.globalStore.retrieveSalesChannels();
            this.globalStore.retrieveBrands();
            this.globalStore.retrieveVehicleClasses();
            this.globalStore.retrieveVehicleTypes();
            this.globalStore.retrieveSurveys();

            if (this.customerStore.customer.mail_address) {
                // @ts-ignore
                this.$posthog.identify(this.customerStore.customer.mail_address, {
                    email: this.customerStore.customer.mail_address,
                    customer_data_configurator: this.customerStore.customer,
                });
            }
        }

        mounted() {
            // Check if all business details are there (they are not if the user reloads during open business details modal)
            if (
                this.customerStore.customer.is_company &&
                this.semiLoginStore.state.loggedIn &&
                !this.semiLoginModalOpen &&
                (!this.customerStore.customer.company || !this.customerStore.customer.fleet_size)
            ) {
                this.businessDetailsModalOpen = true;
            } else {
                this.businessDetailsModalOpen = false;
            }
        }
    }
