var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"car-view"},[_c('div',{staticClass:"container main"},[_c('a',{staticClass:"back-link-box",on:{"click":function($event){return _vm.$router.push({ name: 'selector' })}}},[_c('img',{staticStyle:{"height":"18px"},attrs:{"src":require("@/assets/img/long-arrow-left.svg"),"alt":""}}),_c('span',[_vm._v(_vm._s(_vm.$tc('words.back')))])]),_c('div',{staticClass:"title-container"},[(!_vm.carIsInitiallyLoaded)?_c('div',[_c('b-skeleton',{attrs:{"height":"34px","width":"300px"}}),_c('b-skeleton',{attrs:{"height":"20px","width":"200px"}})],1):(_vm.configuratorStore.car)?_c('div',{staticClass:"is-flex",staticStyle:{"gap":"1rem"}},[_c('div',[_c('h1',{staticClass:"title has-text-left"},[_vm._v(" "+_vm._s(_vm.getCarName())+" ")]),_c('div',{staticStyle:{"display":"flex","gap":"1rem"}},[_c('span',[_vm._v(_vm._s(_vm.configuratorStore.car.model_year))]),(_vm.configuratorStore.car.is_promo)?_c('CarTag',{attrs:{"tagtype":"bd"}}):_vm._e()],1)])]):_vm._e()]),_c('div',{staticClass:"image-container"},[_c('figure',{staticClass:"image"},[(!_vm.carIsFullyLoaded)?[_c('CarImageLoader',{staticClass:"car-image-loader"}),_c('div',{staticClass:"info-box",staticStyle:{"height":"284px","width":"270px"}},[_c('b-skeleton',{attrs:{"height":"284px","width":"270px"}})],1)]:_vm._e(),(
                        !_vm.configuratorStore.carIsLoading &&
                        _vm.configuratorStore.car &&
                        _vm.configuratorStore.config.color
                    )?[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.carIsFullyLoaded),expression:"carIsFullyLoaded"}],staticStyle:{"pointer-events":"none"},attrs:{"src":_vm.carImgSrc,"alt":_vm.getCarName(),"title":_vm.getCarName()},on:{"load":() => {
                                _vm.imageLoaded = true;
                                _vm.imageIsInCache = true;
                            }}}),_c('InfoBox',{directives:[{name:"show",rawName:"v-show",value:(_vm.carIsInitiallyLoaded),expression:"carIsInitiallyLoaded"}],staticClass:"info-box"})]:_vm._e()],2),_c('div',{staticClass:"included-items"},[_c('div',{staticClass:"is-flex is-align-items-center"},[(_vm.configuratorStore.car && _vm.configuratorStore.car.promo_content)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.promoText)}}):_c('div',[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$tc('messages.all_included')))]),_c('p',[_vm._v(_vm._s(_vm.$tc('messages.all_included_text')))])]),_c('div',[_c('WithdrawalBadge',{staticClass:"my-5 mx-4",attrs:{"persistent-tooltip":true}})],1)]),_c('InclusiveIcons',{attrs:{"wrap":true,"align-left":true}})],1)]),_c('h4',{staticClass:"title is-4 mt-6 mb-3"},[_vm._v(_vm._s(_vm.$tc('words.additional_equipment')))]),_c('p',{staticClass:"mb-5"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$tc('messages.draft.additional_equipment_p1'))}}),_c('br'),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$tc('messages.draft.additional_equipment_p2'))}})]),_c('AdditionalEquipment')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }