
    import { Component, Vue } from 'vue-property-decorator';
    import LazyImage from '@/components/LazyImage.vue';
    import { getName as getCarName } from '@/util/car';
    import { normalizeSvgViewBoxes } from '@/util/misc';
    import InfoBox from '@/components/configurator/InfoBox.vue';
    import { useConfiguratorStore } from '@/store/configurator';
    import WithdrawalBadge from '@/components/WithdrawalBadge.vue';
    import CarImageLoader from '@/components/CarImageLoader.vue';
    import { Country } from '@/enums/Country';
    import CarTag from '@/components/tags/CarTag.vue';
    import { marked } from 'marked';
    import AdditionalEquipment from '@/components/additionals/AdditionalEquipment.vue';

    @Component({
        components: {
            AdditionalEquipment,
            // has to be imported like that because of recursively importing GlobalStore store? @TODO: figure out
            InclusiveIcons: () => import('@/components/InclusiveIcons.vue'),
            LazyImage,
            InfoBox,
            WithdrawalBadge,
            CarImageLoader,
            CarTag,
        },
    })
    export default class CarDesktopView extends Vue {
        imageLoaded = false;
        imageIsInCache = false;
        country = Country;

        get promoText() {
            if (this.configuratorStore.car?.promo_content) {
                // promo content is here, render and use it.
                return this.renderMarkdownContent(this.configuratorStore.car.promo_content);
            }

            return null;
        }

        /**
         * Add a custom markdown renderer to print p's instead of h1/h2 tags in that case.
         *
         * @param content markdown content
         */
        renderMarkdownContent(content: string): string {
            const renderer = {
                heading(text, level) {
                    if (level === 1) {
                        // h1, so set title class
                        return `<p class="title">${text}</p>`;
                    }

                    if (level === 2) {
                        // h2, so just use p tag
                        return `<p>${text}</p>`;
                    }

                    // otherwise, return 'normal' h-tag markup
                    return `<h${level}>${text}</h${level}>`;
                },
            };

            // use the renderer ;-)
            marked.use({ renderer });

            // return html content
            return marked.parse(content);
        }

        get carIsFullyLoaded() {
            return (
                this.imageLoaded &&
                !this.configuratorStore.carIsLoading &&
                !!this.configuratorStore.car &&
                !!this.configuratorStore.config.color
            );
        }

        get carIsInitiallyLoaded() {
            return (
                !this.configuratorStore.carIsLoading &&
                !!this.configuratorStore.car &&
                !!this.configuratorStore.config.color
            );
        }

        get configuratorStore() {
            return useConfiguratorStore();
        }

        get carImgSrc() {
            if (!this.configuratorStore.config.color) {
                throw new Error('color is undefined!');
            }

            let imgUrl = `${this.configuratorStore.config.color.car_image}?w=1300`;

            if (this.configuratorStore.car?.watermark_image) {
                imgUrl += `&wat=1&wat_url=${this.configuratorStore.car.watermark_image.image}&wat_scale=100`;
            }

            if (process.env.NODE_ENV === 'development') {
                imgUrl = imgUrl.replaceAll('localhost:8001', 'id-cs.com');
            }

            // when image loads fast enough (mostly because its already cashed) show no loading animation
            this.imageIsInCache = false;
            setTimeout(() => {
                if (!this.imageIsInCache) {
                    this.imageLoaded = false;
                }
            }, 50);

            return `https://adhgfzvyfq.cloudimg.io/v7/${imgUrl}`;
        }

        getCarName() {
            return getCarName(this.configuratorStore.car);
        }

        updated() {
            normalizeSvgViewBoxes(this.$el.querySelectorAll('.make-icon > svg'));
        }

        mounted() {
            this.imageLoaded = false;
            this.$nextTick(function () {
                // Code that will run only after the entire view has been rendered
                normalizeSvgViewBoxes(this.$el.querySelectorAll('.make-icon > svg'));
            });
        }
    }
