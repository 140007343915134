
    import { Component, Vue } from 'vue-property-decorator';
    import AdditionalEquipment from '@/components/additionals/AdditionalEquipment.vue';
    import ExtraOptions from '@/components/additionals/ExtraOptions.vue';
    import ChargingEquipment from '@/components/additionals/ChargingEquipment.vue';
    import { useConfiguratorStore } from '@/store/configurator';

    @Component({
        components: {
            AdditionalEquipment,
            ExtraOptions,
            ChargingEquipment,
        },
    })
    export default class Additionals extends Vue {
        get configuratorStore() {
            return useConfiguratorStore();
        }
    }
