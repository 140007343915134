
    import { Component, Vue } from 'vue-property-decorator';
    import { IAdditionalEquipmentOptionalItem } from '@/interfaces/IAdditionalEquipmentOptionalItem';
    import { AdditionalEquipment as AdditionalEquipmentInteraction } from '@/interfaces/instalytics/interaction';
    import { normalizeSvgViewBoxes } from '@/util/misc';
    import { useConfiguratorStore } from '@/store/configurator';
    import { useCustomerStore } from '@/store/customer';
    import { useGlobalStore } from '@/store/global';
    import { ConfigurationOption } from '@/enums/ConfigurationOption';

    @Component
    export default class AdditionalEquipment extends Vue {
        get globalStore() {
            return useGlobalStore();
        }

        get configuratorStore() {
            return useConfiguratorStore();
        }

        get customerStore() {
            return useCustomerStore();
        }

        hasAdditionalEquip(af: IAdditionalEquipmentOptionalItem): boolean {
            return Boolean(
                this.configuratorStore.priceRequest?.additional_equipment?.find(
                    (item) => item.id === af.id && item.type === af.equip_type,
                ),
            );
        }

        async onEquipClick(af: IAdditionalEquipmentOptionalItem) {
            if (this.configuratorStore.priceRequest?.additional_equipment === undefined) {
                throw new Error('additional_equipment is undefined');
            }

            if (this.hasAdditionalEquip(af)) {
                for (const [
                    i,
                    item,
                ] of this.configuratorStore.priceRequest.additional_equipment.entries()) {
                    if (item.id === af.id && item.type === af.equip_type) {
                        // save the current additional_equipment list for instalytics
                        const oldValue: AdditionalEquipmentInteraction[] = [];
                        for (const reqItem of this.configuratorStore.priceRequest
                            .additional_equipment) {
                            const option =
                                this.configuratorStore.priceResult?.optionals.additional_equipment.find(
                                    (item) =>
                                        item.id === reqItem.id && reqItem.type === item.equip_type,
                                );

                            if (option) {
                                oldValue.push({
                                    id: option.id,
                                    name: option.name,
                                });
                            }
                        }

                        this.configuratorStore.priceRequest.additional_equipment.splice(i, 1);

                        const newValue: AdditionalEquipmentInteraction[] = [];
                        for (const reqItem of this.configuratorStore.priceRequest
                            .additional_equipment) {
                            const option =
                                this.configuratorStore.priceResult?.optionals.additional_equipment.find(
                                    (item) =>
                                        item.id === reqItem.id && reqItem.type === item.equip_type,
                                );

                            if (option) {
                                newValue.push({
                                    id: option.id,
                                    name: option.name,
                                });
                            }
                        }

                        await this.configuratorStore.sendToInstalytics(
                            ConfigurationOption.ADDITIONAL_FIXTURES,
                            oldValue,
                            newValue,
                        );
                    }
                }
            } else {
                // save the current additional_equipment list for instalytics
                // const oldValue = [...this.configuratorStore.priceRequest.additional_equipment];
                const oldValue: AdditionalEquipmentInteraction[] = [];
                for (const reqItem of this.configuratorStore.priceRequest.additional_equipment) {
                    const option =
                        this.configuratorStore.priceResult?.optionals.additional_equipment.find(
                            (item) => item.id === reqItem.id && reqItem.type === item.equip_type,
                        );

                    if (option) {
                        oldValue.push({
                            id: option.id,
                            name: option.name,
                        });
                    }
                }

                this.configuratorStore.priceRequest.additional_equipment.push({
                    type: af.equip_type,
                    id: af.id,
                });

                const newValue: AdditionalEquipmentInteraction[] = [];
                for (const reqItem of this.configuratorStore.priceRequest.additional_equipment) {
                    const option =
                        this.configuratorStore.priceResult?.optionals.additional_equipment.find(
                            (item) => item.id === reqItem.id && reqItem.type === item.equip_type,
                        );

                    if (option) {
                        newValue.push({
                            id: option.id,
                            name: option.name,
                        });
                    }
                }

                await this.configuratorStore.sendToInstalytics(
                    ConfigurationOption.ADDITIONAL_FIXTURES,
                    oldValue,
                    newValue,
                );
            }

            await this.configuratorStore.retrievePrice();
        }

        mounted() {
            this.$nextTick(function () {
                // Code that will run only after the entire view has been rendered
                normalizeSvgViewBoxes(this.$el.querySelectorAll('span.svg > svg'));
            });
        }
    }
