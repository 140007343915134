import { Country } from '@/enums/Country';
import { useGlobalStore } from '@/store/global';

export function getURLFromWebsite(path: string) {
    const globalStore = useGlobalStore();

    if (path === 'datenschutzerklärung') {
        path = 'datenschutz';
    }
    if (path === 'agb') {
        if (globalStore.country === Country.DE) {
            return `//${process.env.VUE_APP_WEBSITE_DOMAIN}/instadrive_agb_de`;
        } else if (globalStore.country === Country.AT) {
            return `//${process.env.VUE_APP_WEBSITE_DOMAIN}/instadrive_agb_at`;
        } else if (globalStore.country === Country.CH) {
            return `//${process.env.VUE_APP_WEBSITE_DOMAIN}/instadrive_agb_ch`;
        } else if (globalStore.country === Country.FR) {
            return `//${process.env.VUE_APP_WEBSITE_DOMAIN}/cgv_instadrive_fr`;
        } else {
            throw Error('Keine AGBs gefunden für dieses Land');
        }
    }
    if (path === 'leistungsbeschreibung') {
        if (globalStore.country === Country.DE) {
            return `//${process.env.VUE_APP_WEBSITE_DOMAIN}/leistungsbeschreibung_instadrive_de`;
        } else if (globalStore.country === Country.AT) {
            return `//${process.env.VUE_APP_WEBSITE_DOMAIN}/leistungsbeschreibung_instadrive_at`;
        } else if (globalStore.country === Country.CH) {
            return `//${process.env.VUE_APP_WEBSITE_DOMAIN}/leistungsbeschreibung_instadrive_ch`;
        } else if (globalStore.country === Country.FR) {
            return `//${process.env.VUE_APP_WEBSITE_DOMAIN}/description_prestations_instadrive_fr`;
        } else {
            throw Error('Keine Leistungsbeschreibung gefunden für dieses Land');
        }
    }
    if (path === 'mentions légales') {
        path = 'mentions-legales';
    }
    if (path === 'politique de confidentialité') {
        path = 'politique-de-confidentialite';
    }

    return `//${process.env.VUE_APP_WEBSITE_DOMAIN}/${globalStore.country}/${path}`;
}
