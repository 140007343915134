
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import PriceTableV2 from '@/components/PriceTableV2.vue';
    import CalendlyWidget from '@/components/CalendlyWidget.vue';
    import BenefitsPopup from '@/components/BenefitsPopup.vue';
    import { useGlobalStore } from '@/store/global';
    import { useConfiguratorStore } from '@/store/configurator';
    import { Country } from '@/enums/Country';
    import { useOfferStore } from '@/store/offer';
    import { useCustomerStore } from '@/store/customer';
    import { useSemiLoginStore } from '@/store/semilogin';

    @Component({
        components: {
            PriceTableV2,
            CalendlyWidget,
            BenefitsPopup,
        },
    })
    export default class ResultBox extends Vue {
        @Prop() deliveryString!: string;
        @Prop({ default: false }) showButton;
        @Prop({ default: false }) hideOnDesktop;
        country: any;
        priceModal = false;
        showPopup = false;
        offerButtonIsLoading = false;

        get globalStore() {
            return useGlobalStore();
        }

        get configuratorStore() {
            return useConfiguratorStore();
        }

        get customerStore() {
            return useCustomerStore();
        }

        get semiLoginStore() {
            return useSemiLoginStore();
        }

        onContinueClicked() {
            this.$emit('onContinue');
            this.semiLoginStore.state.seenPriceDetailPageOnce = true;
        }

        async sendOffer() {
            this.offerButtonIsLoading = true;
            const offerStore = useOfferStore();

            await offerStore.dispatchOffer();
            this.offerButtonIsLoading = false;
        }

        beforeCreate() {
            this.country = Country;
        }

        async openPriceModal() {
            this.priceModal = true;
        }
    }
